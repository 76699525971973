import * as React from "react";
import OrganizationApp from "organization";
import SEO from "../components/seo";

const OrganizationPage = (): JSX.Element => {
  return (
    <React.Fragment>
      <SEO title="Organization" />
      <OrganizationApp basepath="organization" />
    </React.Fragment>
  );
};

export default OrganizationPage;
